import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ProductTab from "../../elements/ProductTab"
import RelatedSlider from "../../sliders/Related"
import ThumbSlider from "../../sliders/Thumb"
import PropTypes from "prop-types"
import React from "react"

import useLangSwitch from "constants/LanguageSwicth"
import usePrice from "hooks/usePrice"
import { map } from "lodash"
import { Link } from "react-router-dom"
import { FaHeart, FaRegHeart, FaSpinner } from "react-icons/fa"
import useCartActions from "hooks/useCartAction"
import { getLocalCart } from "store/actions"

import { Markup } from "interweave"
import { useWindowSize } from "react-use"
import useWishlistAction from "hooks/useWishlistAction"
import storage from "util/localStorage"
import { local_token } from "helpers/api_methods"

const ProductDetails = ({ product }) => {
  const [quantity, setQuantity] = useState(1)
  const [varients, setVarients] = useState({})

  const [seeMore, setSeeMore] = useState(false)
  const [customLoading, setCustomLoading] = useState(false)

  const dispatch = useDispatch()

  const { addOrRemoveLoad, relatedProd, shipping, productDetail } = useSelector(
    state => ({
      addOrRemoveLoad: state.Cart.addOrRemoveLoad,
      relatedProd: state.Products.relatedProduct,
      productDetail: state.Products.productDetail,
      shipping: state.SiteSettings.siteSettings.shipping,
    })
  )
  const token = localStorage.getItem(local_token)

  const user_favourite =
    storage?.get("user_favourite") !== null && storage.get("user_favourite")

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { currencyCode, oldPrice, price, stock, currentVarient } = usePrice(
    product,
    varients
  )
  // const { currencyCode, oldPrice, price, stock, currentVarient } = useCallback(
  //   usePrice(product, varients),
  //   [product, varients]
  // )

  const ReturnDuration = shipping?.returnDuration

  const handleCart = product => {
    const options = {
      product: product?._id,
      varient: varients?._id ? varients?._id : product?.varients[0]?._id,
      quantity: quantity,
      operation: "add",
    }
    if (stock >= 1) {
      useCartActions(options, dispatch, product)
      if (!token) {
        setCustomLoading(true)
        dispatch(getLocalCart())
      }
    }
  }

  const handleWishlist = productId => {
    useWishlistAction(dispatch, productId)
  }

  const handleVarients = varient => {
    if (varient?._id === varients?._id) {
      setVarients(varients)
    } else {
      setVarients(varient)
    }
  }

  const handleDecreaseQty = () => {
    setQuantity(quantity > 1 ? quantity - 1 : 1)
  }

  const handleIncreaseQty = () => {
    if (stock === quantity) {
      setQuantity(quantity)
    } else {
      setQuantity(quantity + 1)
    }
  }

  useEffect(() => {
    const varient = product?.varients[0]
    setVarients(varient)
  }, [product])

  const { width } = useWindowSize()

  const discountPercentage = Math.floor(((oldPrice - price) / price) * 100)

  if (productDetail?.message === "You're not product") {
    return (
      <>
        <div className="d-flex align-items-center flex-revers-col emptyCart mt-30 h:300px justify-content-center sm:p-4">
          <div className="text-center">
            <h4 className="text-brand cartempty">
              No product found with this name, don&#39;t worry
            </h4>
            <p className="text-black cartemptySub">
              “Explore our wide selection and find something you like”
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/shop-list" className="m-auto w-fit ">
                <span className="btn btn-xs submit-auto-width font-smaller hover-up mt-30">
                  <i className="fi-rs-home mr-5"></i> Back To Shopping
                </span>
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  } else
    return (
      <>
        <section className="mt-50 mb-50 sm:mt-0" style={{ minHeight: "100vh" }}>
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-xl-10 col-lg-4-5 m-auto productSingleContainer ">
                <div className="product-detail accordion-detail">
                  <div className="row mb-50 mt-30 sm:mt-0">
                    <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5 sm:p-0 sm:mb-0">
                      <div className="detail-gallery">
                        <div className="product-image-slider">
                          <ThumbSlider product={product} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className="detail-info  pr-30 pl-30">
                        {stock <= 0 && (
                          <span className="stock-status out-stock">
                            {" "}
                            Out Off Stock{" "}
                          </span>
                        )}
                        {stock <= 3 && stock !== 0 && (
                          <span className="stock-status in-stock mx-2">
                            Hurry, Only a few left!
                          </span>
                        )}
                        <p>{product?.barcode}</p>
                        <h2 className="title-detail font-mainHead fw-400">
                          {language?.name}{" "}
                          {currentVarient && <span>({currentVarient})</span>}
                        </h2>

                        <div className="clearfix product-price-cover">
                          <div className="product-price primary-color float-left">
                            <span className="current-price  font-bigger text-black">
                              {currencyCode}
                              {price}
                            </span>
                            <span className="d-flex align-items-center">
                              {oldPrice && (
                                <span className="old-price font-md m-0 font-head mx-1">
                                  {currencyCode}
                                  {oldPrice}
                                </span>
                              )}
                              {(product?.discountPercentage > 0 ||
                                discountPercentage > 0) && (
                                <span className="save-price text-green font-md color3 m-0 font-head ">
                                  {product?.discountPercentage ??
                                    discountPercentage}
                                  % Off
                                </span>
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="short-desc mb-30">
                          <span className="font-lg">
                            <Markup
                              content={language?.description?.slice(
                                0,
                                `${
                                  seeMore ? language?.description?.length : 390
                                }`
                              )}
                            />
                            {language?.description?.length >= 390 && (
                              <span
                                className="text-primary"
                                style={{ textDecoration: "underline" }}
                                onClick={() => setSeeMore(!seeMore)}
                              >
                                {!seeMore ? "read more" : "show less"}
                              </span>
                            )}
                          </span>
                        </div>

                        <div className="attr-detail attr-size">
                          <ul className="list-filter size-filter font-small">
                            {map(product?.varients, (varient, key) => (
                              <li
                                className={`${
                                  varients?._id === varient?._id ? "active" : ""
                                }`}
                                key={key}
                              >
                                <Link
                                  to={"#!"}
                                  onClick={() => handleVarients(varient)}
                                >
                                  {varient?.value}
                                  {varient?.unit}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="bt-1 border-color-1 mt-30 mb-30"></div>
                        <div className="detail-extralink">
                          <div className="detail-qty border radius">
                            <a onClick={handleDecreaseQty} className="qty-down">
                              <i className="fi-rs-angle-small-down"></i>
                            </a>
                            <span className="qty-val">{quantity}</span>
                            <a onClick={handleIncreaseQty} className="qty-up">
                              <i className="fi-rs-angle-small-up"></i>
                            </a>
                          </div>
                          <div className="product-extra-link2">
                            {width >= 480 ? (
                              <button
                                onClick={e => handleCart(product)}
                                className={
                                  "button button-add-to-cart " +
                                  `${
                                    stock <= 0 &&
                                    "bg-gray text-white opacity-half cursor-notAllowed"
                                  }`
                                }
                              >
                                Add to cart{" "}
                                {addOrRemoveLoad && (
                                  <FaSpinner className="mx-2 spinner" />
                                )}
                              </button>
                            ) : (
                              <div className="flotingCart">
                                <div>
                                  <p className="mb-0 font-normal">
                                    {language?.name.slice(0, 45) +
                                      `${
                                        language?.name?.length >= 45
                                          ? "..."
                                          : ""
                                      }`}
                                  </p>
                                  <span className="current-price fw-900 font-mainHead  text-brand">
                                    {currencyCode}
                                    {price}
                                    <span className="text-gray font-normal">
                                      {" "}
                                      x {quantity}
                                    </span>
                                  </span>
                                </div>
                                <button
                                  onClick={e => handleCart(product)}
                                  className={
                                    "button button-add-to-cart " +
                                    `${
                                      stock <= 0 &&
                                      "bg-gray text-white opacity-half cursor-notAllowed"
                                    }`
                                  }
                                >
                                  Add to cart{" "}
                                  {addOrRemoveLoad && (
                                    <FaSpinner className="mx-2 spinner" />
                                  )}
                                </button>
                              </div>
                            )}

                            <a
                              aria-label="Add To wish list"
                              className="action-btn bg-white"
                              onClick={e => handleWishlist(product?._id)}
                            >
                              {user_favourite?.length >= 1 &&
                              user_favourite?.includes(product?._id) ? (
                                <FaHeart
                                  className="text-danger"
                                  size={"1.2rem"}
                                  style={{ marginTop: "-6px" }}
                                />
                              ) : (
                                <FaRegHeart
                                  size={"1.2rem"}
                                  className="text-black"
                                  style={{ marginTop: "-6px" }}
                                />
                              )}
                            </a>
                          </div>

                          {stock === quantity && (
                            <span className="stock-status bg-yellow-light">
                              You have reached the maximum Quantity allowed
                            </span>
                          )}
                        </div>

                        <ul className="product-meta font-xs color-grey mt-50">
                          <li className="mb-5">
                            {ReturnDuration && (
                              <span>
                                Easy {ReturnDuration} days{" "}
                                <Link to={"/return-policy"}>
                                  return and exchanges{" "}
                                </Link>
                                available!
                              </span>
                            )}
                          </li>
                          <li className="mb-5">
                            Category:&nbsp;
                            {map(product?.categories, (cat, key) => (
                              <Link
                                to={{
                                  pathname: "/shop-list",
                                  query: {
                                    cat: cat?.primaryLang?.name?.replace(
                                      / /g,
                                      "-"
                                    ),
                                    isProduct: false,
                                  },
                                }}
                                key={key}
                              >
                                {cat?.primaryLang?.name},&nbsp;
                              </Link>
                            ))}
                          </li>

                          <li>
                            Availability:
                            <span className="in-stock text-success ml-5">
                              {stock} Items In Stock
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <>
                    {product?.primaryLang?.description && (
                      <ProductTab details={product?.primaryLang} />
                    )}
                    {relatedProd?.length > 0 && (
                      <div className="row mt-60">
                        <div className="col-12">
                          <h3 className="section-title font-head style-1 mb-30">
                            Related products
                          </h3>
                        </div>
                        <div className="col-12">
                          <div className="row related-products position-relative">
                            <RelatedSlider />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
}

export default ProductDetails

ProductDetails.propTypes = {
  product: PropTypes.any,
}
