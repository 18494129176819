import { useSelector } from "react-redux"
import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import SwiperCore, { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { map } from "lodash"
import category1 from "assets/imgs/category/dress.png"

SwiperCore.use([Navigation, Autoplay])

import CustomizedLoader from "components/Common/loaders/CustomizedLoader"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import { useWindowSize } from "react-use"
import useCloudinary from "hooks/useCloudinaryImage"

const CategorySlider = () => {
  const history = useHistory()
  const location = useLocation()
  const { width } = useWindowSize()

  const selectCategory = (e, catName) => {
    const removeSpace = catName?.replace(/ /g, "-")
    e.preventDefault()

    if (catName === "all") {
      history.push({
        pathname: "/shop-list",
      })
    } else {
      history.push({
        pathname: "/shop-list",
        query: {
          ...location.query,
          cat: removeSpace,
          isProduct: false,
        },
      })
    }
  }

  const { allCategories, loading } = useSelector(state => ({
    allCategories: state.Classification.categories,
    loading: state.Classification.loading,
  }))

  function getRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  return (
    <>
      {loading ? (
        <>
          {width >= 480 ? (
            <CustomizedLoader
              style={{ height: "100%", minHeight: "200px" }}
              count={6}
              mobileCount={3}
              height={"100%"}
              innerClass={"mx-2"}
            />
          ) : (
            <ProductListLoader key={0} className="isMoblieCate mt-3" />
          )}
        </>
      ) : (
        <Swiper
          autoplay={false}
          navigation={{
            prevEl: ".custom_prev_ct1",
            nextEl: ".custom_next_ct1",
          }}
          className="custom-class"
          slidesPerView={4.7}
          breakpoints={{
            480: {
              slidesPerView: 4.7,
            },
            640: {
              slidesPerView: 5,
            },
            768: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 8,
            },
            1200: {
              slidesPerView: 10,
            },
          }}
        >
          <SwiperSlide>
            <div
              className={`card-2 custo_cat_slider wow animate__animated animate__fadeInUp`}
              onClick={e => selectCategory(e, "all")}
            >
              <figure className="img-hover-scale overflow-hidden allCat">
                <a>
                  <img src={category1} alt="" className="" />
                </a>
              </figure>
              <h6
                className=""
                style={{
                  whiteSpace: "break-spaces",
                  margin: "auto",
                  padding: "0 9px",
                  wordWrap: "break-word",
                  fontWeight: "400",
                }}
              >
                <span className="catrgory_title">All</span>
                <br />
                <span className="catrgory_subtitle">14 items</span>
              </h6>
            </div>
          </SwiperSlide>
          {map(allCategories, (cat, key) => (
            <SwiperSlide key={key}>
              <div
                className={`card-2 custo_cat_slider wow animate__animated animate__fadeInUp`}
                onClick={e => selectCategory(e, cat?.primaryLang?.name)}
              >
                <figure className="img-hover-scale overflow-hidden">
                  <a>
                    <img
                      src={
                        cat?.logoImages[0]?.url
                          ? useCloudinary(cat?.logoImages[0]?.url, "w_120")
                          : category1
                      }
                      alt=""
                    />
                  </a>
                </figure>
                <h6
                  className=""
                  style={{
                    whiteSpace: "break-spaces",
                    margin: "auto",
                    padding: "0 9px",
                    wordWrap: "break-word",
                    fontWeight: "400",
                  }}
                >
                  <span className="catrgory_title">
                    {cat?.primaryLang?.name}
                  </span>
                  <br />
                  <span className="catrgory_subtitle">14 items</span>
                </h6>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <div
        className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow"
        id="carausel-10-columns-arrows"
      >
        <span className="slider-btn slider-prev slick-arrow custom_prev_ct1">
          <i className="fi-rs-arrow-small-left"></i>
        </span>
        <span className="slider-btn slider-next slick-arrow custom_next_ct1">
          <i className="fi-rs-arrow-small-right"></i>
        </span>
      </div>
    </>
  )
}

export default CategorySlider
// export default connect(null, { updateProductCategory })(CategorySlider)
