import React from "react"
import Layout from "components/layout/Layout"
import Gmap from "components/elements/Gmap"

import contact2 from "../../assets/imgs/page/contact-2.png"
import { useSelector } from "react-redux"
import UseMap from "hooks/useGmap"

function Contact() {
  const { company } = useSelector(state => ({
    company: state?.SiteSettings.siteSettings?.company,
  }))

  return (
    <>
      <Layout parent="Home" sub="Pages" subChild="Contact">
        <div className="page-content pt-50">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-12 m-auto">
                <section className="row align-items-end mb-50">
                  <div className="col-lg-8 mb-lg-0 mb-md-5 mb-sm-5 ">
                    <h4 className="mb-20 text-brand">
                      Welcome to {company?.name}
                    </h4>
                    <p className="mb-20 w-75">
                      MAHALO is a palatable and memorable chocolate that can be
                      enjoyed as a part of your healthy life. MAHALO known in
                      the market for premium chocolate quality and fine
                      packaging making our products perfect gifting for any type
                      of occasion. Browse our delicious range of chocolate
                      boxes, pouches and gift hampers filled with lot of care,
                      affection and love to spread happiness towards your loving
                      ones.. We use only the finest ingredients in creating our
                      chocolates which are available in dark, milk and white
                      varieties with premium quality. Rich, smooth and tasty
                      chocolates that leaves you wanting more and more.
                    </p>
                  </div>
                  <div className="col-md-4 mb-4 mb-md-0">
                    <div className="border-radius-15 overflow-hidden">
                      <div id="map-panes" className="leaflet-map">
                        <a
                          href="https://www.google.com/maps/place/Mahalo+Dates+%26+Chocolates+(Mahalo+Distributions+Pvt+Ltd)/@10.9751654,76.177382,17z/data=!3m1!4b1!4m5!3m4!1s0x3ba7cd52033d6a73:0xa0259c9ab140f910!8m2!3d10.9751654!4d76.177382"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <UseMap position={[10.9751654, 76.177382]} />
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <section className="container mb-50 d-none d-md-block"></section>
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-12 m-auto">
                <section className="mb-50">
                  <div className="row mb-60">
                    <div className="col-md-4">
                      <h4 className="mb-15 text-brand">Shop</h4>
                      <a href="/"> {company?.name}</a>
                      <br /> {company?.address?.addressLine1} <br />
                      {company?.address?.district}
                      <br />
                      {company?.address?.state} {company?.address?.pinCode}
                      <br />
                      <abbr title="Phone">Phone:</abbr>{" "}
                      {company?.contactDetails?.mobile}
                      <br />
                      <abbr title="Email">Email: </abbr>{" "}
                      {company?.contactDetails?.email}
                      <br />
                      <a
                        href="https://www.google.com/maps/place/Mahalo+Dates+%26+Chocolates+(Mahalo+Distributions+Pvt+Ltd)/@10.9751654,76.177382,17z/data=!3m1!4b1!4m5!3m4!1s0x3ba7cd52033d6a73:0xa0259c9ab140f910!8m2!3d10.9751654!4d76.177382"
                        target={"_blank"}
                        rel="noreferrer"
                        className="btn btn-sm font-weight-bold text-white mt-20 border-radius-5 btn-shadow-brand hover-up"
                      >
                        <i className="fi-rs-marker mr-5"></i>View map
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Contact
