import { map } from "lodash"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import moment from "moment"
import usePrice from "hooks/usePrice"
import { GoPrimitiveDot } from "react-icons/go"

export const handleOrderStatus = status => {
  if (status === "Processing") return "text-info"
  if (status === "Shipped") return "text-green"
  if (status === "Delivered") return "text-green"
  if (status === "Returned") return "text-danger"
  if (status === "Cancelled") return "text-danger"
}

function OrderItemMob({ singleView, orderAgain }) {
  const { orders, totalOrders } = useSelector(state => ({
    orders: state.Cart.allOrder.orders,
    totalOrders: state.Cart.allOrder.totalOrders,
  }))

  const { currencyCode } = usePrice()

  return (
    <div className="">
      {map(orders, (order, key) => (
        <div
          className="border-b-4 py-3 bg-lightGray px-3 mb-2 rounded-sm mobileOrderCard"
          key={key}
        >
          <div
            className={`d-flex align-items-center justify-content-end ${handleOrderStatus(
              order?.orderStatus
            )}`}
          >
            <GoPrimitiveDot size={"1.2rem"} />
            <p className="font-smaller">{order?.orderStatus}</p>
          </div>
          <div className="" style={{ marginTop: "-25px" }}>
            <p className="">
              {" "}
              <Link
                to="#!"
                className="text-black "
                style={{ textDecoration: "underline" }}
                onClick={() => singleView(order?._id)}
              >
                {order?.orderId}
              </Link>
            </p>
            <p className="mb-0">
              Order placed on {moment(order?.createdAt).format("MMM DD, YYYY")}
            </p>
            <p className="text-success mb-0"> {order?.paymentType}</p>
            <div className="d-flex align-items-start mt-4 justify-content-between">
              <Link
                to="#!"
                onClick={() => orderAgain(order?._id)}
                className="btn-sm btn btn-custom bg-info  btn-download hover-up"
              >
                Order Again
              </Link>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <span>
                  <h4>
                    {" "}
                    {currencyCode} {order?.totalPrice}
                  </h4>
                </span>
                <Link
                  to="#!"
                  onClick={() => singleView(order?._id)}
                  className="btn-md pt-0"
                >
                  ({order?.orderItems?.length} item) View detail
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default OrderItemMob
OrderItemMob.propTypes = {
  singleView: PropTypes.func,
  orderAgain: PropTypes.func,
}
