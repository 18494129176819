import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import useLangSwitch from "constants/LanguageSwicth"
import usePrice from "hooks/usePrice"
import useCloudinary from "hooks/useCloudinaryImage"

function ListVertical({ product }) {
  const { currencyCode, oldPrice, price } = usePrice(product)

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const removeSpace = product?.primaryLang?.name?.replace(/ /g, "-")

  const discountPercentage = Math.floor(((oldPrice - price) / price) * 100)

  return (
    <>
      <div className="product-list-small  animated animated">
        <article className="row align-items-center hover-up">
          <figure className="col-md-4 mb-0 ">
            <Link to={`/products/${removeSpace}`}>
              <img
                src={useCloudinary(product.images[0]?.url, "c_thumb,w_350")}
                alt=""
              />
            </Link>
          </figure>
          <div className="col-md-8 mb-0">
            <h6>
              <Link to={`/products/${removeSpace}`}>{language?.name}</Link>
            </h6>
            <div className="product-price">
              <span>
                {currencyCode}
                {price}
              </span>
              <span className="old-price">{oldPrice}</span>
              {oldPrice && (
                <span className="price-per">
                  &nbsp;{discountPercentage}% off
                </span>
              )}
            </div>
          </div>
        </article>
      </div>
    </>
  )
}

export default ListVertical

ListVertical.propTypes = {
  product: PropTypes.any,
}
