import React from "react"

import about_bg from "assets/imgs/banner/about_bg.png"

function AboutSection() {
  return (
    <section
      className="intro_section row"
      style={{ backgroundImage: `url(${about_bg})` }}
    >
      <div className="intro_content col-6  flex">
        <div className="content_heder">
          <h1>About</h1>
          <div className="content_text">
            <h1>Us</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Praesentium, labore. Lorem ipsum, dolor sit amet consectetur
              adipisicing elit. Amet provident cupiditate dolore ex harum cum
              hic distinctio illo optio ea error laudantium expedita alias, quas
              aspernatur debitis voluptas, sed veritatis!Lorem Lorem adipisicing
              elit. Amet provident cupiditate dolore ex harum cum hic distinctio
              illo optio ea error laudantium expedita alias, quas aspernatur
              debitis voluptas, sed veritatis!Lorem Lorem
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection
