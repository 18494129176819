import React, { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

import iconHeart from "assets/imgs/theme/icons/icon-heart.png"
import iconHeartBlack from "assets/imgs/theme/icons/black/icon-heart.png"
import iconCart from "assets/imgs/theme/icons/icon-cart.png"
import iconCartBlack from "assets/imgs/theme/icons/black/icon-cart.png"
import iconUser from "assets/imgs/theme/icons/icon-user.png"
import iconSearchBlack from "assets/imgs/theme/icons/black/fi-rs-search.png"

import Search from "components/ecommerce/search/Search"

import {
  getCategories,
  getCollections,
  getFavouriteItems,
  getLocalCart,
  getQuicklist,
  getUserCart,
  logoutUser,
  showLoginPopupAction,
} from "store/actions"
import { map } from "lodash"
import CartItemList from "components/elements/cartItemComp2"
import usePrice from "hooks/usePrice"
import EmptyCartSvg from "components/Common/emptyCart"
import useLangSwitch from "constants/LanguageSwicth"
import { useOutsideAlerter } from "hooks/useOutSideClick"
import { useWindowSize } from "react-use"
import { LogoSm, MainLogo } from "assets/imgs/logos"
import { useLocalCartPirces } from "hooks/useCartAction"
import { local_token } from "helpers/api_methods"
import { FiUser, FiUserCheck } from "react-icons/fi"
import useCloudinary from "hooks/useCloudinaryImage"
import { AiOutlineHeart, AiOutlineUser } from "react-icons/ai"
import { BsCart, BsCart2 } from "react-icons/bs"

const Header = ({ totalCartItems, toggleClick }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const cateRef = useRef(null)
  const location = useLocation()
  const { width } = useWindowSize()

  const token = localStorage.getItem(local_token)

  const {
    cartItems,
    userCart,
    company,
    specialMessages,
    favourites,
    collections,
    localCart,
    user,
    allCategories,
  } = useSelector(state => ({
    user: state.Login.user,
    cartItems: state.Cart.cartItems,
    localCart: state.Cart.localCart,
    userCart: state.Cart.userCart,
    company: state?.SiteSettings.siteSettings?.company,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
    favourites: state.Cart.favourites,
    collections: state.LayoutGroup.collections,
    allCategories: state.Classification.categories,
  }))

  const [isToggled, setToggled] = useState(false)
  const [scroll, setScroll] = useState(false)

  const [scrollY, setScrollY] = useState()

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(cateRef, setOutSide)

  useEffect(() => {
    const onScroll = e => {
      setScrollY(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    if (scrollY >= 100) {
      setScroll(true)
    } else {
      setScroll(false)
    }

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollY])

  const handleToggle = () => setToggled(!isToggled)

  const handleLoginPopup = () => dispatch(showLoginPopupAction())

  const handleLogout = () => {
    dispatch(logoutUser(history))
  }

  useEffect(() => {
    dispatch(getUserCart())
    dispatch(getCategories("", "all"))
    dispatch(getFavouriteItems(1, 10))
    dispatch(getQuicklist(1, 10))
  }, [dispatch])

  useEffect(() => {
    dispatch(getCollections())
  }, [dispatch])

  const { currencyCode } = usePrice()

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const handleLoginFun = path => {
    if (path) {
      history.push(path)
    }
  }

  useEffect(() => {
    if (outSide === true) {
      setToggled(false)
      setOutSide(false)
    }
  }, [outSide])

  const isHotDeals = collections?.filter(i =>
    i?.primaryLang?.title === "Hot Deals" ? true : false
  )

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else if (localCart?.length >= 1) {
      return localCart?.filter(i => i?.quantity !== 0)
    } else {
      return []
    }
  }

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart())
    }
  }, [])

  const { itemTotal } = useLocalCartPirces()

  const selectCategory = (e, catName) => {
    const removeSpace = catName?.replace(/ /g, "-")
    e.preventDefault()

    if (catName === "all") {
      history.push({
        pathname: "/shop-list",
      })
    } else {
      history.push({
        pathname: "/shop-list",
        query: {
          ...location.query,
          cat: removeSpace,
          isProduct: false,
        },
      })
    }
  }

  return (
    <>
      <header
        className={`sticky-bar ${scroll === true ? "stick" : ""} ${
          width <= 480 && location.pathname === "/" ? "header-area" : ""
        }  header-style-1 header-height-2 `}
      >
        {language?.productOffer && (
          <div className="mobile-promotion">
            {language?.productOffer && <span>{language?.productOffer}</span>}
          </div>
        )}
        {width > 480 ? (
          <div
            className={`${
              scroll === false && location.pathname === "/"
                ? "scorio-header-middle"
                : ""
            }  header-middle header-middle-ptb-1 d-lg-block`}
          >
            <div className="container">
              <div className="header-wrap">
                <div className="header-right mx-4">
                  {/* <div className="search-style-2 position-relative max-w:600px">
                    <Search />
                  </div> */}
                  <div className="logo logo-width-2">
                    <Link to="/">
                      <img
                        src={
                          scroll === false && location.pathname === "/"
                            ? company?.logo[0]?.url
                              ? useCloudinary(company?.logo[0]?.url, "w_300")
                              : LogoSm
                            : MainLogo
                        }
                        height="60px"
                        width={"120px"}
                        alt="logo"
                        className="fit-contain mx-4 custom_logo_img"
                      />
                    </Link>
                  </div>

                  <div className="header-action-right">
                    <div
                      className={
                        "header-action-2 " +
                        `${
                          scroll === false && location.pathname === "/"
                            ? "text-black"
                            : "text-white"
                        }`
                      }
                    >
                      <div className="header-action-icon-2">
                        <Link
                          to={"#!"}
                          onClick={() => handleLoginFun("/wishlist")}
                        >
                          <span>
                            {/* <img
                              className="svgInject"
                              alt="mahalo"
                              src={iconHeart}
                            /> */}
                            <AiOutlineHeart />
                            {favourites?.total >= 1 ? (
                              <span
                                className={`pro-count ${
                                  location.pathname === "/" ? "blue" : "red"
                                }`}
                              >
                                {favourites?.total}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </Link>
                        <Link
                          to={"#!"}
                          onClick={() => handleLoginFun("/wishlist")}
                        >
                          <span className="lable">Wish List</span>
                        </Link>
                      </div>

                      <div className="header-action-icon-2">
                        <Link to={"#!"} onClick={() => handleLoginFun("/cart")}>
                          <div className="mini-cart-icon">
                            {/* <img alt="mahalo" src={iconCart} /> */}
                            <BsCart
                              color={
                                scroll === false && location.pathname === "/"
                                  ? ""
                                  : "white"
                              }
                            />
                            {handleCartItem()?.length >= 1 ? (
                              <span
                                className={`pro-count ${
                                  location.pathname === "/" ? "blue" : "red"
                                }`}
                              >
                                {handleCartItem()?.length}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </Link>

                        <Link to={"#!"} onClick={() => handleLoginFun("/cart")}>
                          <span className="lable">Cart</span>
                        </Link>

                        <div className="cart-dropdown-wrap cart-dropdown-hm2">
                          {handleCartItem()?.length >= 1 ? (
                            <>
                              <ul>
                                {map(handleCartItem(), (item, key) => (
                                  <Fragment key={key}>
                                    <CartItemList item={item} />
                                  </Fragment>
                                ))}
                              </ul>
                              <div className="shopping-cart-footer">
                                <div className="shopping-cart-total">
                                  <h4>
                                    Total
                                    <span className="secondary-color">
                                      {currencyCode}{" "}
                                      {userCart?.totalPrice
                                        ? userCart?.totalPrice
                                        : itemTotal}
                                    </span>
                                  </h4>
                                </div>
                                <div className="shopping-cart-button">
                                  <Link
                                    onClick={() => handleLoginFun("/cart")}
                                    to={"#!"}
                                  >
                                    View cart
                                  </Link>
                                </div>
                              </div>
                            </>
                          ) : (
                            <EmptyCartSvg style={{ height: "170px" }} />
                          )}
                        </div>
                      </div>

                      <div className="header-action-icon-2 user_icons">
                        {token ? (
                          <>
                            <Link to="/user-account">
                              <span>
                                {/* <img
                                  className="svgInject mx-1"
                                  alt="user"
                                  src={iconUser}
                                /> */}
                                <AiOutlineUser />
                              </span>
                            </Link>
                            <Link to="/user-account">
                              <span>
                                {user?.name ? (
                                  <span style={{ textTransform: "capitalize" }}>
                                    {user?.name?.split(" ")[0]}
                                  </span>
                                ) : (
                                  <span className="lable ml-0">Account</span>
                                )}
                              </span>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link to="#!" onClick={handleLoginPopup}>
                              <span>
                                {/* <img
                                  className="svgInject mx-1"
                                  alt="user"
                                  src={iconUser}
                                /> */}
                                <FiUser />
                              </span>
                            </Link>
                            <Link to="#!" onClick={handleLoginPopup}>
                              <span>
                                <span className="lable ml-0">Login</span>
                              </span>
                            </Link>
                          </>
                        )}
                        {token ? (
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                            <ul>
                              <li>
                                <Link to="/user-account">
                                  <span>
                                    <i className="fi fi-rs-user mr-10"></i>
                                    My Account
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: "/user-account",
                                    state: { index: 2 },
                                  }}
                                >
                                  <span>
                                    <i className="fi fi-rs-location-alt mr-10"></i>
                                    Order Tracking
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: "/user-account",
                                    state: { index: 4 },
                                  }}
                                >
                                  <span>
                                    <i className="fi fi-rs-label mr-10"></i>
                                    My Address
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"#!"}
                                  onClick={() => handleLoginFun("/wishlist")}
                                >
                                  <span>
                                    <i className="fi fi-rs-heart mr-10"></i>
                                    My Wish List
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="#!" onClick={handleLogout}>
                                  <span>
                                    <i className="fi fi-rs-sign-out mr-10"></i>
                                    Sign out
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {location.pathname === "/" && scroll !== true && (
                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block  font-heading">
                  <nav className="catgory_ul">
                    <ul>
                      <li>
                        <Link to={"#!"} onClick={e => selectCategory(e, "all")}>
                          All
                        </Link>
                      </li>
                      {map(allCategories, (cat, key) => (
                        <li key={key}>
                          <Link
                            to={"#!"}
                            onClick={e =>
                              selectCategory(e, cat?.primaryLang?.name)
                            }
                          >
                            {cat?.primaryLang?.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className={
              scroll &&
              (location.pathname === "/shop-list" ||
                location.pathname === "/categroies" ||
                location.pathname === "/collections")
                ? "header-bottom header-bottom-bg-color sticky-bar stick"
                : "header-bottom header-bottom-bg-color sticky-bar"
            }
          >
            {scroll === true &&
            (location.pathname === "/shop-list" ||
              location.pathname === "/categroies" ||
              location.pathname === "/collections") ? (
              <div
                className={`search-style-2 position-relative max-w:600px myMobileSearch ${
                  scroll === true ? "scroll" : ""
                }`}
              >
                <Search isMobile={true} />
              </div>
            ) : (
              <div className="container">
                <div className="header-wrap header-space-between position-relative">
                  <div
                    className="logo logo-width d-block d-lg-none d-flex align-items-center"
                    style={{ padding: width > 768 && "15px 0" }}
                  >
                    <div className="header-action-icon d-block d-lg-none ">
                      <div
                        className="burger-icon burger-icon-white"
                        onClick={toggleClick}
                      >
                        <span className="burger-icon-top"></span>
                        <span className="burger-icon-mid"></span>
                        <span className="burger-icon-bottom"></span>
                      </div>
                    </div>
                    <Link
                      to="/"
                      className="mx-1"
                      style={{ height: "20px", width: "80px" }}
                    >
                      <img
                        src={company?.logo[0]?.url ?? MainLogo}
                        alt={company?.name ?? "logo"}
                        height="100%"
                        width={"100%"}
                        className="fit-contain"
                      />
                    </Link>
                  </div>

                  {width > 992 && scroll === true && (
                    <div className="header-action-icon-2 d-flex align-items-center">
                      <Link
                        className="position-relative"
                        to={"#!"}
                        onClick={() => handleLoginFun("/wishlist")}
                      >
                        <span>
                          <img
                            className="svgInject"
                            alt={company?.name ?? "logo"}
                            src={iconHeart}
                          />
                        </span>
                      </Link>
                      <Link
                        to={"#!"}
                        className="position-relative mx-2"
                        onClick={() => handleLoginFun("/cart")}
                      >
                        <div className="mini-cart-icon">
                          <img alt={company?.name ?? "logo"} src={iconCart} />
                          {handleCartItem()?.length >= 1 ? (
                            <span
                              className="pro-count text-white navIcon2 bg-darkGreen px-1"
                              style={{ borderRadius: "50%" }}
                            >
                              {handleCartItem()?.length}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Link>
                      {token && (
                        <Link to="/user-account">
                          <span>
                            <img
                              className="svgInject"
                              alt="user"
                              src={iconUser}
                            />
                          </span>
                        </Link>
                      )}
                    </div>
                  )}

                  <div className="header-action-right d-block d-lg-none">
                    <div className="header-action-2">
                      <Link
                        to="/user-account"
                        className="d-flex align-items-center justify-content-center"
                      >
                        {user?.name ? (
                          <span>
                            <span style={{ textTransform: "capitalize" }}>
                              {user?.name?.split(" ")[0]}
                            </span>
                          </span>
                        ) : (
                          <FiUserCheck
                            className="primary-color"
                            size={"20px"}
                          />
                        )}
                      </Link>
                      {!token ? (
                        <div className="header-action-icon-2">
                          <Link
                            className="m-0 d-flex align-items-center justify-content-center"
                            to="#!"
                            onClick={handleLoginPopup}
                          >
                            <span className="primary-color sm:color-black">
                              Login
                            </span>
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="header-action-icon-2">
                        <Link
                          to={"#!"}
                          onClick={() => handleLoginFun("/wishlist")}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span className="mini-cart-icon">
                            <img
                              alt={company?.name ?? "logo"}
                              src={iconHeartBlack}
                            />
                            {favourites?.total >= 1 && (
                              <span className="pro-count white">
                                {favourites?.total}
                              </span>
                            )}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {/* <div
          className={
            scroll
              ? "header-bottom header-bottom-bg-color sticky-bar"
              : "header-bottom header-bottom-bg-color sticky-bar"
          }
        >
          <div className="container">
            <div className="header-wrap header-space-between position-relative">
              <div className="header-nav d-none d-lg-flex">
                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block  font-heading">
                  <nav>
                    <ul>
                      <li>
                        <Link href="/">
                          <a className="active">
                            Home
                            <i className="fi-rs-angle-down"></i>
                          </a>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link href="/">
                              <a>Home 1</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/index-2">
                              <a>Home 2</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/index-3">
                              <a>Home 3</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/index-4">
                              <a>Home 4</a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link href="/page-about">
                          <a>About</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/shop-grid-right">
                          <a>
                            Shop
                            <i className="fi-rs-angle-down"></i>
                          </a>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link href="/shop-grid-right">
                              <a>Shop Grid – Right Sidebar</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/products">
                              <a>Shop Grid – Left Sidebar</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/shop-list-right">
                              <a>Shop List – Right Sidebar</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/shop-list-left">
                              <a>Shop List – Left Sidebar</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/shop-fullwidth">
                              <a>Shop - Wide</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/shop-filter">
                              <a>Shop - Filter</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/shop-wishlist">
                              <a>Shop – Wishlist</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/shop-cart">
                              <a>Shop – Cart</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/shop-checkout">
                              <a>Shop – Checkout</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/shop-compare">
                              <a>Shop – Compare</a>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <a href="#">
                          Vendors <i className="fi-rs-angle-down"></i>
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <Link href="/vendors">
                              <a>Vendors Grid</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/vendors-list">
                              <a>Vendors List</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/vendor-dashboard">
                              <a>Vendor Dashboard</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/vendor-guide">
                              <a>Vendor Guide</a>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="position-static">
                        <Link href="/#">
                          <a>
                            Mega menu
                            <i className="fi-rs-angle-down"></i>
                          </a>
                        </Link>
                        <ul className="mega-menu">
                          <li className="sub-mega-menu sub-mega-menu-width-22">
                            <a className="menu-title" href="#">
                              Fruit & Vegetables
                            </a>
                            <ul>
                              <li>
                                <a href="#">Meat & Poultry</a>
                              </li>
                              <li>
                                <a href="#">Fresh Vegetables</a>
                              </li>
                              <li>
                                <a href="#">Herbs & Seasonings</a>
                              </li>
                              <li>
                                <a href="#">Cuts & Sprouts</a>
                              </li>
                              <li>
                                <a href="#">Exotic Fruits & Veggies</a>
                              </li>
                              <li>
                                <a href="#">Packaged Produce</a>
                              </li>
                            </ul>
                          </li>
                          <li className="sub-mega-menu sub-mega-menu-width-22">
                            <a className="menu-title" href="#">
                              Breakfast & Dairy
                            </a>
                            <ul>
                              <li>
                                <a href="#">Milk & Flavoured Milk</a>
                              </li>
                              <li>
                                <a href="#">Butter and Margarine</a>
                              </li>
                              <li>
                                <a href="#">Eggs Substitutes</a>
                              </li>
                              <li>
                                <a href="#">Marmalades</a>
                              </li>
                              <li>
                                <a href="#">Sour Cream</a>
                              </li>
                              <li>
                                <a href="#">Cheese</a>
                              </li>
                            </ul>
                          </li>
                          <li className="sub-mega-menu sub-mega-menu-width-22">
                            <a className="menu-title" href="#">
                              Meat & Seafood
                            </a>
                            <ul>
                              <li>
                                <a href="#">Breakfast Sausage</a>
                              </li>
                              <li>
                                <a href="#">Dinner Sausage</a>
                              </li>
                              <li>
                                <a href="#">Chicken</a>
                              </li>
                              <li>
                                <a href="#">Sliced Deli Meat</a>
                              </li>
                              <li>
                                <a href="#">Wild Caught Fillets</a>
                              </li>
                              <li>
                                <a href="#">Crab and Shellfish</a>
                              </li>
                            </ul>
                          </li>
                          <li className="sub-mega-menu sub-mega-menu-width-34">
                            <div className="menu-banner-wrap">
                              <a href="#">
                                <img
                                  src="/assets/imgs/banner/banner-menu.png"
                                  alt="Nest"
                                />
                              </a>
                              <div className="menu-banner-content">
                                <h4>Hot deals</h4>
                                <h3>
                                  Don&#39;t miss
                                  <br />
                                  Trending
                                </h3>
                                <div className="menu-banner-price">
                                  <span className="new-price text-success">
                                    Save to 50%
                                  </span>
                                </div>
                                <div className="menu-banner-btn">
                                  <a href="#">Shop now</a>
                                </div>
                              </div>
                              <div className="menu-banner-discount">
                                <h3>
                                  <span>25%</span>
                                  off
                                </h3>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link href="/blog-category-grid">
                          <a>
                            Blog
                            <i className="fi-rs-angle-down"></i>
                          </a>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link href="/blog-category-grid">
                              <a>Blog Category Grid</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/blog-category-list">
                              <a>Blog Category List</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/blog-category-big">
                              <a>Blog Category Big</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/blog-category-fullwidth">
                              <a>Blog Category Wide</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/#">
                              <a>
                                Single Post
                                <i className="fi-rs-angle-right"></i>
                              </a>
                            </Link>
                            <ul className="level-menu level-menu-modify">
                              <li>
                                <Link href="/blog-post-left">
                                  <a>Left Sidebar</a>
                                </Link>
                              </li>
                              <li>
                                <Link href="/blog-post-right">
                                  <a>Right Sidebar</a>
                                </Link>
                              </li>
                              <li>
                                <Link href="/blog-post-fullwidth">
                                  <a>No Sidebar</a>
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link href="/#">
                          <a>
                            Pages
                            <i className="fi-rs-angle-down"></i>
                          </a>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link href="/page-about">
                              <a>About Us</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/page-contact">
                              <a>Contact</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/page-account">
                              <a>My Account</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/page-login-register">
                              <a>login/register</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/page-purchase-guide">
                              <a>Purchase Guide</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/page-privacy-policy">
                              <a>Privacy Policy</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/page-terms">
                              <a>Terms of Service</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/page-404">
                              <a>404 Page</a>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link href="/page-contact">
                          <a>Contact</a>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </header>
    </>
  )
}

export default Header

Header.propTypes = {
  totalCartItems: PropTypes.any,
  toggleClick: PropTypes.any,
  totalWishlistItems: PropTypes.any,
}
