import Layout from "components/layout/Layout"
import React, { useEffect, useState } from "react"
import UserAddressList from "./userAddressList"
import { useDispatch, useSelector } from "react-redux"
import {
  couponValidate,
  createRazorpayOrder,
  getLocalCart,
  getPaymentKey,
  getUserAddress,
  getUserCart,
  makeUserOrder,
  showToasterAction,
  verifyRazorpayOrder,
} from "store/actions"
import usePrice from "hooks/usePrice"
import useLangSwitch from "constants/LanguageSwicth"
import { Link, useHistory } from "react-router-dom"
import SmallCardLoader from "components/Common/loaders/SmallCardLoader"

import useRazorpay from "react-razorpay"
import { FaLock, FaSpinner } from "react-icons/fa"
import CheckoutItem from "./checkoutItem"
import { useWindowSize } from "react-use"
import ProductLoader from "components/Common/loaders/ProductLoader"
import {
  googlepay,
  mastercard,
  paytm,
  phonepay,
  rupay,
  UPI,
  visa,
} from "assets/imgs/payment"

const Checkout = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const Razorpay = useRazorpay()
  const { width } = useWindowSize()

  const {
    userCart,
    loading,
    cartItems,
    specialMessages,
    paymentType,
    paymentKey,
    coupon,
    couponLoad,
    orderLoad,
    paymentInfo,
    paymentLoad,
    paymentVerifyInfo,
    localCart,
  } = useSelector(state => ({
    userCart: state.Cart.userCart,
    loading: state.Cart.loading,
    cartItems: state.Cart.cartItems,
    localCart: state.Cart.localCart,
    coupon: state.Cart.coupon,
    couponLoad: state.Cart.couponLoad,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
    paymentType: state.SiteSettings.siteSettings.paymentType,
    paymentKey: state.SiteSettings.paymentKey.paymentKey,
    orderLoad: state.Cart.orderLoad,
    paymentInfo: state.Cart.paymentInfo,
    paymentLoad: state.Cart.paymentLoad,
    paymentVerifyInfo: state.Cart.paymentVerifyInfo,
  }))

  const [changePaymentType, setChangePaymentType] = useState("Online Payment")
  const [couponText, setCouponText] = useState("")
  const [shippingAddress, setShippingAddress] = useState(null)

  useEffect(() => {
    dispatch(getUserCart())
    dispatch(getPaymentKey())
    dispatch(getUserAddress())
  }, [dispatch])

  const { currencyCode } = usePrice()
  const token = localStorage.getItem("babyday_token")

  const noStock = cartItems?.length <= 0 ? true : false

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const handlePaymentInput = () => {
    if (paymentType === "Cash on Delivery") {
      return "cod"
    } else if (paymentType === "Online Payment") {
      return "online"
    } else {
      return "both"
    }
  }

  const handlePaymentChange = value => {
    setChangePaymentType(value)
  }

  const initatePayment = () => {
    const options = {
      key: paymentKey?.keyId,
      amount: paymentInfo?.amount,
      currency: paymentInfo?.currency,
      order_id: paymentInfo?.id,

      prefill: {
        name: shippingAddress?.name,
        email: "ecom.babyday@gmail.com",
        contact: shippingAddress?.contactNumber,
      },
      readonly: {
        email: true,
        contact: true,
      },

      handler: async res => {
        try {
          dispatch(verifyRazorpayOrder(res))
        } catch (error) {
          console.log(error)
        }
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    }

    const rzpay = new Razorpay(options)
    rzpay.open()
  }

  const handleRazorPayPayment = async () => {
    try {
      dispatch(createRazorpayOrder())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (paymentInfo?.id) {
      initatePayment()
    }
  }, [paymentInfo?.id])

  useEffect(() => {
    if (paymentVerifyInfo?.isVerified) {
      const input = {
        shippingInfo: shippingAddress,
        paymentType: changePaymentType,
        onlinePayment: {
          isVerified: paymentVerifyInfo?.isVerified,
          ...paymentVerifyInfo?.razorpayRes,
        },
      }
      console.log(input)
      dispatch(makeUserOrder(input, history))
    }
  }, [paymentVerifyInfo?.isVerified])

  const handleSubmitCoupon = e => {
    e.preventDefault()
    dispatch(couponValidate(couponText))
  }

  const handleCODPayment = () => {
    const orderData = {
      shippingInfo: shippingAddress,
      paymentType: changePaymentType,
    }

    if (shippingAddress === null || !shippingAddress) {
      dispatch(
        showToasterAction({
          data: {
            title: "Shipping Address",
            message: "Select a Address First",
          },
          type: "default",
        })
      )
    } else {
      dispatch(makeUserOrder(orderData, history))
      console.log("orderData=> ", orderData)
    }
  }

  // useEffect(() => {
  //   console.clear()
  // }, [])

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else {
      return localCart?.filter(i => i.quantity !== 0)
    }
  }

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart())
    }
  }, [])

  useEffect(() => {
    history.listen(action => {
      if (action !== "PUSH") {
        history.go(1)
      }
    })
  }, [history])

  useEffect(() => {
    setChangePaymentType(paymentType)
  }, [paymentType])

  return (
    <>
      <Layout parent="Home" sub="Shop" subChild="Checkout">
        <section className="mt-50 mb-50 myCheckout">
          <div className="container px-5">
            <div className="row">
              <div className="col-lg-8">
                <div className="row mb-50">
                  <div className="mb-sm-15 mb-lg-0 mb-md-3 ">
                    <div
                      className="panel-collapse login_form checkout_mob"
                      id="loginform"
                    >
                      <UserAddressList
                        setShippingAddress={setShippingAddress}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order_prices">
                <div className="p-0 pb-20 ml-30 sm:m-0">
                  <form className="apply-coupon" onSubmit={handleSubmitCoupon}>
                    <input
                      type="text"
                      placeholder="Enter Coupon Code..."
                      onChange={e => setCouponText(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="btn btn-md text-black white-space-nowrap"
                      style={{
                        border: "1px solid #ececec",
                        backgroundColor: "#ececec",
                      }}
                    >
                      {couponLoad ? (
                        <FaSpinner className="spinner" />
                      ) : (
                        "Apply Coupon"
                      )}
                    </button>
                  </form>

                  {coupon?.response?.message && (
                    <p className="mx-2 pt-2 text-danger">
                      {coupon?.response?.message}
                    </p>
                  )}
                  {typeof coupon?.response === "number" && (
                    <p className="mx-2 pt-2 text-green">{coupon?.success}</p>
                  )}
                </div>
                {loading ? (
                  <>
                    <ProductLoader height="200px" />
                  </>
                ) : (
                  <div className="border p-40 cart-totals ml-30 mb-30  ">
                    <div className="payment_method">
                      <div className="mb-10">
                        <h5>Order summary</h5>
                      </div>

                      <div className="table-responsive order_table table-border-tr-none max-h:400px">
                        {loading ? (
                          <SmallCardLoader />
                        ) : (
                          <table
                            className={!noStock ? "table no-border" : "d-none"}
                          >
                            <tbody>
                              {handleCartItem()?.map((item, i) => (
                                <CheckoutItem
                                  item={item}
                                  key={i}
                                  isSmall={true}
                                />
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                      <div className="mb-30">
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                          <p>Sub total</p>
                          <p>
                            {currencyCode} {userCart?.itemsPrice}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                          <p>Shipping charge</p>
                          {userCart?.shippingPrice > 0 ? (
                            <p>
                              {currencyCode} {userCart?.shippingPrice}
                            </p>
                          ) : (
                            <p className="text-green">Free</p>
                          )}
                        </div>
                        {userCart?.couponDiscount ? (
                          <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                            <p>Coupon Discount</p>
                            <p>
                              {currencyCode} {userCart?.couponDiscount}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {userCart?.totalDiscount ? (
                          <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                            <p>Total Discount</p>
                            <p>
                              {currencyCode} {userCart?.totalDiscount}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}{" "}
                        {userCart?.totalPrimeDiscount ? (
                          <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                            <p>Prime Discount</p>
                            <p>
                              {currencyCode} {userCart?.totalPrimeDiscount}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-20">
                          <h6>Total</h6>
                          <h6>
                            {currencyCode} {userCart?.totalPrice}
                          </h6>
                        </div>
                      </div>

                      <div className="d-flex align-items-end sm:flex-col">
                        <div className="payment_option sm:text-start">
                          {handlePaymentInput() === "both" && (
                            <>
                              {CashOnDeliveryInput()}
                              {OnlinePaymentInput()}
                            </>
                          )}
                        </div>
                      </div>
                      {width >= 480 ? (
                        <div className="w-100 mt-4">
                          {" "}
                          {changePaymentType === "Online Payment" ? (
                            <>
                              <Link
                                to="#!"
                                className={`btn w-100 btn-fill-out myButton btn-block ml-auto ${
                                  noStock &&
                                  "bg-disable border-none cursor-notAllowed"
                                }`}
                                onClick={() => handleRazorPayPayment()}
                              >
                                Pay ({currencyCode} {userCart?.totalPrice})
                                {paymentLoad && (
                                  <FaSpinner className="spinner" />
                                )}
                              </Link>
                              <div className="d-flex my-2 justify-content-evenly align-items-center">
                                <img src={paytm} height={"12px"} />
                                <img src={googlepay} height={"12px"} />
                                <img src={UPI} height={"12px"} />
                                <img src={visa} height={"12px"} />
                                <img src={rupay} height={"17px"} />
                                <img src={mastercard} height={"12px"} />
                                {/* <img src={phonepay} height={"20px"} /> */}
                              </div>
                              <p className="text-center mt-4 d-flex align-items-center justify-content-center">
                                <FaLock className="mx-1" /> secure connection{" "}
                              </p>
                            </>
                          ) : (
                            <Link
                              to="#!"
                              onClick={() => handleCODPayment()}
                              className={`btn w-100 btn-fill-out myButton btn-block ml-auto btn-md sm:mt-4 ${
                                noStock &&
                                "bg-disable border-none cursor-notAllowed"
                              }`}
                            >
                              {orderLoad && (
                                <FaSpinner className="spinner mx-1" />
                              )}
                              Place Order
                            </Link>
                          )}
                        </div>
                      ) : (
                        //mobile
                        <div className="mobileFlotingBtn">
                          <div>
                            {userCart?.totalDiscount ? (
                              <del>
                                {currencyCode}{" "}
                                {userCart?.totalPrice + userCart?.totalDiscount}
                              </del>
                            ) : (
                              ""
                            )}
                            <h5 className="text-green">
                              {currencyCode} {userCart?.totalPrice}
                            </h5>
                          </div>{" "}
                          {changePaymentType === "Online Payment" ? (
                            <Link
                              to="#!"
                              className={`btn btn-fill-out myButton btn-block ml-auto ${
                                noStock &&
                                "bg-disable border-none cursor-notAllowed"
                              }`}
                              onClick={() => handleRazorPayPayment()}
                            >
                              Pay ({currencyCode} {userCart?.totalPrice})
                              {paymentLoad && <FaSpinner className="spinner" />}
                            </Link>
                          ) : (
                            <Link
                              to="#!"
                              onClick={() => handleCODPayment()}
                              className={`btn btn-fill-out myButton btn-block ml-auto  ${
                                noStock &&
                                "bg-disable border-none cursor-notAllowed"
                              }`}
                            >
                              {orderLoad && (
                                <FaSpinner className="spinner mx-1" />
                              )}
                              Place Order
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )

  function CashOnDeliveryInput() {
    return (
      <div className="custome-radio">
        <input
          className="form-check-input"
          type="radio"
          name="payment_option"
          id="exampleRadiosCod"
          value={"Cash on Delivery"}
          checked={changePaymentType === "Cash on Delivery"}
          onChange={e => handlePaymentChange(e.target.value)}
        />
        <label className="form-check-label" htmlFor="exampleRadiosCod">
          Cash on Delivery
        </label>
        {changePaymentType === "Cash on Delivery" && (
          <div className="form-group">
            <p className="text-muted mt-5">Select a Address and place order</p>
          </div>
        )}
      </div>
    )
  }

  function OnlinePaymentInput() {
    return (
      <div className="custome-radio">
        <input
          className="form-check-input"
          type="radio"
          name="Online Payment"
          value={"Online Payment"}
          id="exampleRadiosOnline"
          checked={changePaymentType === "Online Payment"}
          onChange={e => handlePaymentChange(e.target.value)}
        />
        <label className="form-check-label" htmlFor="exampleRadiosOnline">
          Online Payment
        </label>
        {changePaymentType === "Online Payment" && (
          <div className="form-group ">
            <p className="text-muted mt-5">
              You can pay with your Debit / Credit Cards or using UPI
              <br />
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default Checkout
