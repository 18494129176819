import React from "react"
import PropTypes from "prop-types"
import { useBanner } from "./useBannerData"
import useCloudinary from "hooks/useCloudinaryImage"
import CategoryTab2 from "components/ecommerce/categoryTab2"

function ProductG5({ groupDetail }) {
  const product = groupDetail?.products

  const handleBannerData = useBanner(
    // groupDetail?.singleCollection,
    // groupDetail?.singleCategory,
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  const CollectionLink = handleBannerData[0]?.primaryLang?.title?.replace(
    / /g,
    "-"
  )

  const CategoryLink = handleBannerData[0]?.primaryLang?.name?.replace(
    / /g,
    "-"
  )

  const handleRouteLink = () => {
    switch (groupDetail?.type) {
      case "single collection":
        return history.push(`/collections/${CollectionLink}`)
      case "single category":
        return history.push({
          pathname: "/shop-list",
          query: {
            cat: CategoryLink,
            isProduct: false,
          },
        })

      default:
        break
    }
  }

  return (
    <section className="product-tabs section-padding position-relative">
      <div className="container">
        <div className="col-lg-12 productFive">
          <div className="section-title mb-4 mt-4 style-2 wow animate__animated animate__fadeIn ">
            <h3>{groupDetail?.primaryLang?.title}</h3>
            {/* <ul className="nav nav-tabs links" id="myTab">
              <li className="nav-item">
                <button
                  className="nav-link active"
                  id="nav-tab-one"
                  type="button"
                >
                  All
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" id="nav-tab-two" type="button">
                  Milks & Dairies
                </button>
              </li>
            </ul> */}
          </div>
          <CategoryTab2
            type={groupDetail?.type}
            products={product}
            handleBannerData={handleBannerData}
            bannerImg={useCloudinary(
              groupDetail?.images[0]?.url ?? "",
              "w_1200"
            )}
            handleRouteLink={handleRouteLink}
          />
        </div>
      </div>
    </section>
  )
}

export default ProductG5

ProductG5.propTypes = {
  groupDetail: PropTypes.object,
}
