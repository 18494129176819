import { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"
import Layout from "components/layout/Layout"
import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"

import {
  getCategories,
  getProducts,
  getProductsByCategory,
} from "store/actions"
import ShopFilterBar from "./shop-filter-bar"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import InfiniteScroll from "react-infinite-scroll-component"
import Youmaylike from "components/elements/Youmaylike"

const ProductsList = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { products, loading } = useSelector(state => ({
    products: state.Products.products,
    loading: state.Products.loading,
  }))

  const [allProducts, setAllProducts] = useState([])
  const [customLoading, setCustomLoading] = useState(false)

  const query = location?.query

  //pagination
  const limit = 20
  const allpages = products?.total / limit
  let [pages, setPages] = useState(1)

  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1)
    }
  }
  //api

  useEffect(() => {
    dispatch(getCategories(1, "all"))
    dispatch(getCategories(1, "sub"))
    dispatch(getCategories(1, "grouped-main"))
  }, [dispatch])

  useEffect(() => {
    const options = {
      searchText: query?.search,
      page: pages,
      custom: "all",
      sort: "",
      limit: limit,
    }

    const CatOptions = {
      ...options,
      custom: query?.cat?.replace(/-/g, " "),
    }

    if (query?.isProduct === false && query?.cat) {
      dispatch(getProductsByCategory(CatOptions))
    } else {
      dispatch(getProducts(options))
    }
  }, [dispatch, query?.cat, query?.isProduct, query?.search, pages])

  const product = products?.products

  useEffect(() => {
    setPages(1)
  }, [query?.cat])

  useEffect(() => {
    if (pages === 1) {
      setAllProducts([])
      setAllProducts([...product])
    } else {
      setAllProducts([...allProducts, ...product])
    }
  }, [products, pages])

  const ids = allProducts?.map(o => o._id)

  const filteredFinalProd = allProducts.filter(
    ({ _id }, index) => !ids.includes(_id, index + 1)
  )

  const handleSreachProd = () => {
    if (query?.isSearch === true) {
      return product
    } else {
      return filteredFinalProd
    }
  }

  useEffect(() => {
    if (loading === true) {
      setCustomLoading(true)
    }
    if (loading === false) {
      setTimeout(() => {
        setCustomLoading(false)
      }, 1500)
    }
  }, [loading])

  return (
    <>
      <Layout noBreadcrumb="d-none" footer={false}>
        <section className="mt-0 mb-50">
          <div className="container">
            <div className="row flex-row-reverse">
              <ShopFilterBar />
              <div className="col-12">
                <InfiniteScroll
                  dataLength={handleSreachProd()?.length ?? 0}
                  next={HandlefetchNextPage}
                  hasMore={true}
                  loader={customLoading && <ProductListLoader key={0} />}
                >
                  <div className="row product-grid-3">
                    {!loading && products?.total === 0 ? (
                      <div>
                        <h6 className="mb-30">
                          <span style={{ fontWeight: "normal" }}>
                            No Products Found in{" "}
                          </span>
                          {query?.cat?.replace(/-/g, " ")}
                        </h6>
                        <Youmaylike />
                      </div>
                    ) : (
                      <>
                        {handleSreachProd()?.map((item, i) => (
                          <div
                            className="col-lg-1-5 col-md-3 col-6 col-sm-4 "
                            key={i}
                          >
                            <ProductCardOne product={item} />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  products: state.products,
})

const mapDidpatchToProps = {
  // openCart,
  //   fetchProduct,
  // fetchMoreProduct,
}

export default connect(mapStateToProps, mapDidpatchToProps)(ProductsList)

ProductsList.propTypes = {
  products: PropTypes.any,
  fetchProduct: PropTypes.any,
}
