import React from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { map } from "lodash"
import { useWindowSize } from "react-use"
import useCloudinary from "hooks/useCloudinaryImage"

function FeatchDeals({ listData, col }) {
  const { width } = useWindowSize()
  const history = useHistory()

  const handleRouteLink = name => {
    if (name?.title) {
      const CollectionLink = name?.title?.replace(/ /g, "-")
      return history.push(`/collections/${CollectionLink}`)
    } else if (name?.name) {
      const CategoryLink = name?.name?.replace(/ /g, "-")
      return history.push({
        pathname: "/shop-list",
        query: {
          cat: CategoryLink,
          isProduct: false,
        },
      })
    } else return
  }
  return (
    <>
      <div className="row">
        {map(listData, (item, key) => (
          <div
            className={"col-lg-4 col-md-6 col-xl-" + `${col ? col : 3}`}
            key={key}
          >
            <div
              className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp"
              data-wow-delay="0"
            >
              <div className="product-img-action-wrap">
                <div className="product-img h:300px">
                  <span
                    onClick={() => handleRouteLink(item?.primaryLang)}
                    className="h-100 w-100 cursor-pointer"
                  >
                    {col === 6 ? (
                      <img
                        src={useCloudinary(
                          item?.bannerImages[0]?.url,
                          "w_1200"
                        )}
                        alt=""
                        className="fit-cover object-end mobileBanner"
                      />
                    ) : (
                      <img
                        src={
                          width >= 480
                            ? item?.logoImages[0]?.url
                            : item?.bannerImages[0]?.url
                        }
                        alt=""
                        className="fit-cover object-end mobileBanner"
                      />
                    )}
                  </span>
                </div>
              </div>
              <div
                className="product-content-wrap max-w:300px"
                style={{
                  marginLeft: col && "1rem",
                  marginTop: col && "-110px",
                }}
              >
                <div className="deals-content">
                  <h2>
                    <Link to={"#!"}>
                      <span>
                        {item?.primaryLang?.title
                          ? item?.primaryLang?.title
                          : item?.primaryLang?.name}
                      </span>
                    </Link>
                  </h2>

                  <div className="product-card-bottom">
                    <div className="product-price"></div>
                    <div className="add-cart">
                      <span
                        onClick={() => handleRouteLink(item?.primaryLang)}
                        className="add cursor-pointer"
                      >
                        <i className="fi-rs-shopping-cart mr-5"></i>Explore{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
export default FeatchDeals

FeatchDeals.propTypes = {
  col: PropTypes.number,
  listData: PropTypes.any,
}
