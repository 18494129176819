import SubCategoryList from "components/ecommerce/filter/subCategoryList"
import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import CategoryProduct2 from "components/ecommerce/filter/CategoryProduct2"
import { useSelector } from "react-redux"

function ShopFilterBar() {
  const history = useHistory()
  const location = useLocation()

  const { subCategories, allCategories } = useSelector(state => ({
    subCategories: state.Classification.subCategories,
    allCategories: state.Classification.categories,
  }))

  const [finalInput, setFinalInput] = useState("")

  useEffect(() => {
    history.push({
      pathname: location?.pathname ? location?.pathname : "/shop-list",
      query: { ...location?.query, search: finalInput },
    })
  }, [finalInput])

  const printValue = debounce(value => setFinalInput(value), 500)

  const handleChangeSearch = e => {
    printValue(e.target.value)
  }

  return (
    <div className={"col-12 primary-sidebar sticky-sidebar "}>
      {allCategories?.length >= 1 && (
        <div className="sidebar-widget widget-category-2 mb-10 custom_category_label p-0 pt-3">
          <CategoryProduct2 showCount={false} />
        </div>
      )}

      {subCategories?.length >= 1 && (
        <div className="sidebar-widget widget-category-2 mb-30 custom_category_label p-0 pt-3">
          <SubCategoryList />
        </div>
      )}
    </div>
  )
}

export default ShopFilterBar
