import { connect, useDispatch, useSelector } from "react-redux"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import PropTypes from "prop-types"
import { getCollections } from "store/actions"
import RotatingGallery from "components/Common/loaders/RotatingGallery"
import collectionImage from "assets/imgs/banner/collections.svg"
import useCloudinary from "hooks/useCloudinaryImage"

const CollectionProduct = ({ className }) => {
  const dispatch = useDispatch()

  const { collections, loading } = useSelector(state => ({
    collections: state.LayoutGroup.collections,
    loading: state.LayoutGroup.loading,
  }))

  useEffect(() => {
    dispatch(getCollections())
  }, [dispatch])

  return (
    <>
      {loading ? (
        <RotatingGallery />
      ) : (
        <div className="row">
          <>
            {collections?.length >= 1 ? (
              map(collections, (coll, key) => (
                <Link
                  to={{
                    pathname: `/collection/${coll?.primaryLang?.title?.replace(
                      / /g,
                      "-"
                    )}`,
                    state: "collection",
                  }}
                  key={key}
                >
                  <article
                    className={
                      "col-12 col-md-6 col-lg-6 text-center hover-up mb-30 animated"
                    }
                  >
                    <img
                      src={useCloudinary(coll?.bannerImages[0]?.url, "w_1200")}
                      className="fit-cover rounded-sm"
                      height="100%"
                      width={"100%"}
                    />
                  </article>
                </Link>
              ))
            ) : (
              <div className="">
                <article
                  className={"col-6 text-center hover-up animated"}
                  style={{ height: "170px", width: "200px" }}
                >
                  <img
                    src={collectionImage}
                    className="fit-contain rounded-sm"
                    height="100%"
                    width={"100%"}
                  />
                </article>
                No collections found
              </div>
            )}{" "}
          </>
        </div>
      )}
    </>
  )
}

export default connect(null, {})(CollectionProduct)

CollectionProduct.propTypes = {
  className: PropTypes.string,
}
