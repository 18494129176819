import React from "react"
import PropTypes from "prop-types"
import ProductCardOne from "./productCards/ProductCardOne"
import { useHistory } from "react-router-dom"
import useCloudinary from "hooks/useCloudinaryImage"
import SliderList from "components/elements/SliderList"
import { useWindowSize } from "react-use"

function CategoryTab2({
  products,
  handleBannerData,
  noHead,
  bannerImg,
  type,
  handleRouteLink,
}) {
  const history = useHistory()
  const { width } = useWindowSize()

  const lastProducts = products?.slice(3, products?.length)

  return (
    <>
      <div className="tab-content wow fadeIn animated">
        <div className={"tab-pane fade show active"}>
          <div className="product-grid-4 row mb-3 ">
            <div className="col-lg-4 mb-3">
              {!noHead && bannerImg && (
                <div className="h-100">
                  <div className="mobileBanner single-hero-slider single-animation-wrap p-5 w-100 ml-auto mb-4 pb-4 h-100 background_image vertical newBan">
                    <img
                      src={
                        useCloudinary(bannerImg, "w_1200")
                        // bannerImg
                        //   ? bannerImg
                        //   : handleBannerData?.bannerImages[0]?.url
                      }
                      className="h-100 w-100"
                      onClick={handleRouteLink}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-8">
              {width < 768 || products?.length <= 6 ? (
                <div className="row">
                  {products?.map((product, i) => (
                    <div
                      className="col-lg-4 col-md-4 col-6 col-sm-6 mobilePadding "
                      key={i}
                    >
                      <ProductCardOne product={product} />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div className="row">
                    {products?.slice(0, 3)?.map((product, i) => (
                      <div
                        className="col-lg-4 col-md-4 col-6 col-sm-6 mobilePadding px-1"
                        key={i}
                      >
                        <ProductCardOne product={product} />
                      </div>
                    ))}
                  </div>
                  <SliderList products={lastProducts} isBig={true} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CategoryTab2

CategoryTab2.propTypes = {
  products: PropTypes.array,
  handleBannerData: PropTypes.any,
  noHead: PropTypes.bool,
  bannerImg: PropTypes.any,
  type: PropTypes.any,
  handleRouteLink: PropTypes.func,
}
