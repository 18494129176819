import { Link, useHistory } from "react-router-dom"
import React from "react"
import SwiperCore, { Navigation, Keyboard, Mousewheel } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.scss"

import PropTypes from "prop-types"

SwiperCore.use([Navigation, Keyboard, Mousewheel])

import { useWindowSize } from "react-use"
import useCloudinary from "hooks/useCloudinaryImage"

function CollectionList2({ listData }) {
  const { width } = useWindowSize()

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 1.2
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 3.5
    } else return 3.5
  }

  const history = useHistory()

  const handleRouteLink = name => {
    if (name?.title) {
      const CollectionLink = name?.title?.replace(/ /g, "-")
      return history.push(`/collections/${CollectionLink}`)
    } else if (name?.name) {
      const CategoryLink = name?.name?.replace(/ /g, "-")
      return history.push({
        pathname: "/shop-list",
        query: {
          cat: CategoryLink,
          isProduct: false,
        },
      })
    } else return
  }
  return (
    <>
      <Swiper
        spaceBetween={20}
        slidesPerView={handleWindowSizeVsSliderPreview()}
        grid={{
          rows: 2,
        }}
        keyboard={{ enabled: true }}
        direction="horizontal"
        // mousewheel={true}
        navigation={{
          prevEl: ".custom_prev_f",
          nextEl: ".custom_next_f",
        }}
        autoplay={true}
        speed={400}
        className="text-center"
        breakpoints={{
          480: {
            slidesPerView: 1.2,
          },
          640: {
            slidesPerView: 2.5,
          },
          768: {
            slidesPerView: 3.5,
          },
          1024: {
            slidesPerView: 3.5,
          },
        }}
      >
        {listData?.map((item, i) => (
          <SwiperSlide key={i} className="h:200px">
            <div className="">
              <div
                className="banner-img wow animate__animated animate__fadeInUp h:200px"
                data-wow-delay="0"
              >
                <img
                  src={useCloudinary(item?.bannerImages[0]?.url, "w_800")}
                  alt=""
                  className="h-100 w-100"
                />
                <div className="banner-text">
                  <h4 className="mb-0">&nbsp;</h4>
                  <span
                    onClick={() => handleRouteLink(item?.primaryLang)}
                    className="cursor-pointer"
                  >
                    <span className="btn btn-xs text-white">
                      Shop Now <i className="fi-rs-arrow-small-right"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow">
          <span className="slider-btn slider-prev slick-arrow custom_prev_f">
            <i className="fi-rs-arrow-small-left"></i>
          </span>
          <span className="slider-btn slider-next slick-arrow custom_next_f">
            <i className="fi-rs-arrow-small-right"></i>
          </span>
        </div>
      </Swiper>

      {/* <div className="col-lg-4 col-md-6">
        <div
          className="banner-img wow animate__animated animate__fadeInUp h:250px"
          data-wow-delay=".2s"
        >
          <img src={banner2} alt="" />
          <div className="banner-text">
            <h4 className="mb-0">&nbsp;</h4>
            <Link to="/products">
              <span className="btn btn-xs text-white">
                Shop Now <i className="fi-rs-arrow-small-right"></i>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-lg-4 d-md-none d-lg-flex">
        <div
          className="banner-img mb-sm-0 wow animate__animated animate__fadeInUp h:250px"
          data-wow-delay=".4s"
        >
          <img src={banner3} alt="" />
          <div className="banner-text">
            <h4 className="mb-0">&nbsp;</h4>
            <Link to="/products">
              <span className="btn btn-xs text-white">
                Shop Now <i className="fi-rs-arrow-small-right"></i>
              </span>
            </Link>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default CollectionList2

CollectionList2.propTypes = {
  listData: PropTypes.any,
}
