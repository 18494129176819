import React, { useEffect, useState } from "react"
import "./preloader.css"
import { withRouter } from "react-router-dom"
import loading from "assets/imgs/logos/logo_red.png"

const PreLoader = () => {
  const [Loading, setLoading] = useState(true)
  useEffect(() => {
    window.addEventListener("load", event => {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    })
  }, [Loading])

  return (
    <>
      {Loading && (
        <>
          {
            <div className="preloaderBg" id="preloader">
              <div className="preloader">
                <img src={loading} alt="logo" />
                <div className="preloader2"></div>
              </div>
            </div>
          }
        </>
      )}
    </>
  )
}

export default withRouter(PreLoader)
