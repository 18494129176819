import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCategories, getLayoutGroups } from "store/actions"
//componets
import Layout from "components/layout/Layout"
import ProductG1 from "./ProductG1"
import ProductG2 from "./ProductG2"
import ProductG3 from "./ProductG3"
import ProductG4 from "./ProductG4"
import CollectionG1 from "./CollectionG1"
import CollectionG2 from "./CollectionG2"
import CollectionG3 from "./CollectionG3"
import CategorySliderContainer from "./CategorySlider"
import HeroSlider from "./HeroSlider"
import DailyFresh from "./DailyFresh"
import SubBanner from "./SubBanner"
import ProductG5 from "./ProductG5"
import GroupLoader from "components/Common/loaders/GroupLoader"
import { useWindowSize } from "react-use"
import InfiniteScroll from "react-infinite-scroll-component"
import AboutSection from "./AboutSection"
import ShopFilterBar from "pages/shop/shop-filter-bar"

export default function Home() {
  const dispatch = useDispatch()
  const { width } = useWindowSize()

  const { layoutGroups, loading, allCategories, banner } = useSelector(
    state => ({
      layoutGroups: state.LayoutGroup.layoutGroups,
      loading: state.LayoutGroup.loading,
      allCategories: state.Classification.categories,
      banner: state.Banner.banners,
    })
  )

  const subBanners = banner?.subBanners

  const limit = 2
  const allpages = layoutGroups?.total / limit

  const [allGroups, setAllGroups] = useState([])
  let [pages, setPages] = useState(1)

  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1)
    }
  }
  const paginatedGp = layoutGroups?.groups

  useEffect(() => {
    if (paginatedGp) {
      setAllGroups([...allGroups, ...paginatedGp])
    }
  }, [layoutGroups, pages])

  const ids = allGroups?.map(o => o._id)

  const filteredFinalGp = allGroups.filter(
    ({ _id }, index) => !ids.includes(_id, index + 1)
  )

  const orderedList = filteredFinalGp?.sort((a, b) => a.priority - b.priority)

  useEffect(() => {
    dispatch(getCategories(1, "all"))
  }, [dispatch])

  useEffect(() => {
    dispatch(getLayoutGroups(pages, "web", limit))
  }, [dispatch, pages, limit])

  const handleLayoutWithProduct = group => {
    switch (group.layout) {
      case "highlight":
        return <ProductG5 groupDetail={group} />
      case "list_horizontal":
        return <ProductG2 groupDetail={group} />
      case "list_vertical":
        return <ProductG1 groupDetail={group} />
      case "carousel":
        return <ProductG3 groupDetail={group} />
      case "list_vertical_sm":
        return <ProductG4 groupDetail={group} />
      case "nested_loop":
        return <DailyFresh groupDetail={group} />
      default:
        return <ProductG1 groupDetail={group} />
    }
  }

  const handleLayoutWithOutProduct = group => {
    switch (group.layout) {
      case "highlight":
        return <CollectionG2 groupDetail={group} col={6} />
      case "list_vertical":
        return <CollectionG1 groupDetail={group} />
      case "list_vertical_sm":
        if (group.layout === "list_vertical_sm")
          return <CollectionG1 groupDetail={group} col={6} />
      case "list_horizontal":
        return <CollectionG2 groupDetail={group} />
      case "carousel":
        return <CollectionG3 groupDetail={group} />
      default:
        return <CollectionG1 groupDetail={group} />
    }
  }

  const groupTypeHandler = group => {
    switch (group.type) {
      case "single collection":
      case "single category":
        return handleLayoutWithProduct(group)
      case "collections":
      case "categories":
        return handleLayoutWithOutProduct(group)
      default:
        return handleLayoutWithProduct(group)
    }
  }

  return (
    <>
      <Layout noBreadcrumb="d-none">
        <div className="hero_wrapp">
          <HeroSlider />
        </div>
        <div className="home_category_list">
          <ShopFilterBar />
        </div>

        {subBanners?.length >= 1 && <SubBanner />}

        <InfiniteScroll
          scrollThreshold={0.6}
          dataLength={filteredFinalGp?.length ?? 0}
          next={HandlefetchNextPage}
          hasMore={true}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          loader={
            loading && (
              <div className="container m-auto mobileGroup">
                <GroupLoader count={4} mobileCount={2} myWidth={"100%"} />
              </div>
            )
          }
        >
          {orderedList?.map((group, idx) => (
            <div className="group_wrapper" key={idx}>
              {groupTypeHandler(group)}
            </div>
          ))}
        </InfiniteScroll>
      </Layout>
    </>
  )
}
