import React, { Fragment, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { map } from "lodash"
import { Link } from "react-router-dom"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"
import { FaSpinner, FaTrash } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { deleteUserAddress, showToasterAction } from "store/actions"
import UserAddressEditPopup from "components/elements/userAddressPopup"
import { useWindowSize } from "react-use"
import { useOutsideAlerter } from "hooks/useOutSideClick"

function UserAddressList({ setShippingAddress, isAccount }) {
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const checkoutAddrressRef = useRef(null)

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(checkoutAddrressRef, setOutSide)

  const { allAddress, loading, shippingInputs, settingLoad, user } =
    useSelector(state => ({
      allAddress: state.Cart.uesrAddress.shippingAddresses,
      loading: state.Cart.addressLoad,
      shippingInputs: state.SiteSettings.siteSettings.shippingInputs,
      settingLoad: state.SiteSettings.loading,
      user: state.Login.user,
    }))

  const [isShow, setIsShow] = useState({ status: false, data: {} })
  const [finalInput, setFinalInput] = useState()

  const [newAddress, setnewAddress] = useState(false)
  const [addressLoad, setAddressLoad] = useState(false)
  const [addreddModalOpen, setAddreddModalOpen] = useState(false)

  const [selectedAddress, setSelectedAddress] = useState()
  const [isSaved, setIsSaved] = useState(false)

  const [allAddressList, setAllAddressList] = useState(allAddress)

  const handleAddNewAddress = () => {
    setnewAddress(!newAddress)
    setAddreddModalOpen(true)
  }

  const handleChangeInput = e => {
    setIsSaved(false)
    setFinalInput({
      ...finalInput,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmitAddress = e => {
    setAddressLoad(true)
    e.preventDefault()
    if (setShippingAddress) {
      setShippingAddress(finalInput)
    }

    setTimeout(() => {
      setAddressLoad(false)
      dispatch(
        showToasterAction({
          data: {
            title: "User Address",
            message: "Shipping Address Updated Successfully",
          },
          type: "success",
        })
      )
      setIsSaved(true)
      window.scroll({
        top: width >= 480 ? 300 : document.body.offsetHeight,
        left: 0,
        behavior: "smooth",
      })
      setAddreddModalOpen(false)
    }, 1000)
    setAllAddressList([
      ...allAddressList,
      {
        isDefault: false,
        isRecentlyAdded: false,
        shippingInfo: finalInput,
        id: allAddressList?.length + 1,
        isLocal: true,
      },
    ])
    setSelectedAddress({
      isDefault: false,
      isRecentlyAdded: false,
      shippingInfo: finalInput,
      _id: allAddressList?.length + 1,
    })
  }

  const handleSelectAddress = item => {
    setSelectedAddress(item)
    if (setShippingAddress) {
      setShippingAddress(item?.shippingInfo)
    }
  }

  const handleDeleteAddress = id => {
    dispatch(deleteUserAddress(id))
  }

  const handleDeleteLocalAddress = id => {
    setAllAddressList(allAddressList?.filter(item => item._id !== id))
  }

  function HandleInputType(input) {
    switch (input) {
      case "contactNumber":
      case "pinCode":
        return "number"
      case "email":
        return "email"
      default:
        return "text"
    }
  }

  useEffect(() => {
    setFinalInput({
      ...finalInput,
      name: user?.name,
      contactNumber: user?.phone,
    })
  }, [user])

  useEffect(() => {
    if (allAddress?.length <= 0) {
      setnewAddress(true)
      setAddreddModalOpen(true)
    }
  }, [allAddress])

  useEffect(() => {
    if (allAddress?.length >= 0) {
      const item = allAddress[0]
      setSelectedAddress(item)
      if (setShippingAddress) {
        setShippingAddress(item?.shippingInfo)
      }
    }
  }, [])

  useEffect(() => {
    if (outSide === true) {
      setAddreddModalOpen(false)
      setOutSide(false)
    }
  }, [outSide])

  const handleLabel = key => {
    switch (key) {
      case "contactNumber":
        return "Contact Number"
      case "pinCode":
        return "Pin Code"
      default:
        return key
    }
  }

  const modifiedShippingInputs = shippingInputs?.map(item => ({
    ...item,
    label: handleLabel(item?.name),
  }))

  useEffect(() => {
    setAllAddressList(allAddress)
  }, [allAddress])

  return (
    <>
      <UserAddressEditPopup
        isShow={isShow?.status}
        setIsShow={setIsShow}
        data={isShow?.data}
      />

      <div
        className={
          !addreddModalOpen
            ? "modal fade custom-modal d-none"
            : "modal fade custom-modal  show d-block mobileEdit"
        }
      >
        <div className="modal-dialog">
          <div className="modal-content px-0 p-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => setAddreddModalOpen(false)}
            ></button>
            <div className="modal-body p-1 sm:p-4">
              <div className="deal custom-border align-items-center flex-col d-flex justify-content-center sm:p-0">
                <div
                  className="deal-top d-flex justify-between align-items-center h-50 pb-0 "
                  style={{
                    padding: "6px 20px 18px",
                  }}
                >
                  <div>
                    <h6 className="text-black">Add New Address </h6>
                  </div>
                </div>
                <div className="deal-content pt-4 px-4 sm:pt-4 pb-10">
                  {" "}
                  <form onSubmit={handleSubmitAddress}>
                    {settingLoad ? (
                      <></>
                    ) : (
                      <div className="row">
                        {map(modifiedShippingInputs, (input, key) => (
                          <Fragment key={key}>
                            {input?.name === "address" ? (
                              <div className="form-group col-lg-6">
                                <textarea
                                  name={input?.name}
                                  placeholder={input?.label}
                                  rows="4"
                                  cols="50"
                                  style={{ minHeight: "10px" }}
                                  onChange={e => handleChangeInput(e)}
                                  required={
                                    input.required === "Required" ? true : false
                                  }
                                ></textarea>
                              </div>
                            ) : (
                              <div className="form-group col-lg-6">
                                <input
                                  type={HandleInputType(input?.name)}
                                  name={input?.name}
                                  placeholder={input?.label}
                                  onChange={e => handleChangeInput(e)}
                                  required={
                                    input.required === "Required" ? true : false
                                  }
                                  // value={handleInputValue(input?.name)}
                                  value={finalInput?.[input?.name]}
                                />
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    )}
                    <div className="w-100 d-flex">
                      {isSaved ? (
                        <span className="ml-auto mt-2 text-info">
                          Address saved
                        </span>
                      ) : (
                        <button
                          type="submit"
                          className="btn bg-black btn-sm w-fit ml-auto mt-30"
                          style={{ borderRadius: "5px", border: "none" }}
                        >
                          Save New Address
                          {addressLoad && (
                            <FaSpinner className="spinner mx-1" />
                          )}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="panel-body position-relative mt-0 sm:mt-4 myAddressNew">
        <h5 className="mb-20 md:mt-50 sm:mt-0 sm:font-size-18">
          Delivery Address
        </h5>
        {loading ? (
          <div className="w-100 d-flex align-items-center justify-content-center h:200px position-absolute left-0 ">
            <FaSpinner size={"1.8rem"} color="green" className="spinner" />
          </div>
        ) : (
          <form className={"d-flex flex-wrap"}>
            {map(allAddressList, (item, key) => (
              <div
                className={"mb-4 custome-radio sm:w-100 " + "w-50"}
                key={key}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="payment_option"
                  id={item?._id}
                  checked={selectedAddress?._id === item?._id}
                  onChange={() => handleSelectAddress(item)}
                />
                <label
                  className="form-check-label text-size-16 text-black sm:font-size-14"
                  htmlFor={item?._id}
                >
                  +91 {item?.shippingInfo?.contactNumber}
                </label>

                <div className="mx-4 mb-1 myAddress">
                  <p className="mb-0">{item?.shippingInfo?.name}</p>
                  <p className="mb-0">{item?.shippingInfo?.email}</p>
                  <p className="mb-0">{item?.shippingInfo?.address}</p>
                  <p className="mb-0">{item?.shippingInfo?.addressLine1}</p>
                  <p className="mb-0">{item?.shippingInfo?.street}</p>
                  <p className="mb-0">{item?.shippingInfo?.landmark}</p>
                  <p className="mb-0">{item?.shippingInfo?.area}</p>
                  <p className="mb-0">{item?.shippingInfo?.district}</p>
                  <p className="mb-0">{item?.shippingInfo?.state}</p>
                </div>
                {item?.isLocal ? (
                  <div className="px-4 d-flex align-items-center">
                    <FaTrash
                      className="cursor-pointer"
                      onClick={() => handleDeleteLocalAddress(item?._id)}
                    />
                  </div>
                ) : (
                  <div className="px-4 d-flex align-items-center">
                    <a
                      href="#"
                      onClick={() =>
                        setIsShow({ ...isShow, status: true, data: item })
                      }
                      className="btn-small"
                    >
                      Edit
                    </a>
                    <FaTrash
                      className="mx-2 cursor-pointer"
                      onClick={() => handleDeleteAddress(item?._id)}
                    />
                  </div>
                )}
              </div>
            ))}
          </form>
        )}

        <div className="form-group">
          {!isAccount && (
            <Link
              to={"#!"}
              onClick={() => handleAddNewAddress()}
              className="d-flex align-items-center text-size-16 mb-20"
            >
              {newAddress ? (
                <AiOutlineMinus className="mr-1" />
              ) : (
                <AiOutlinePlus className="mr-1" />
              )}
              Add a New Address
            </Link>
          )}
        </div>
      </div>

      <div
        className={
          !addreddModalOpen
            ? "modal-backdrop fade d-none"
            : "modal-backdrop fade show"
        }
      ></div>
    </>
  )
}

export default UserAddressList

UserAddressList.propTypes = {
  setShippingAddress: PropTypes.any,
  isAccount: PropTypes.bool,
}
