import Intro1 from "components/sliders/Intro1"
import React from "react"

function HeroSlider() {
  return (
    <section className="home-slider position-relative">
      <div className="myHeroContainer">
        <div className="home-slide-cover">
          <Intro1 />
        </div>
      </div>
    </section>
  )
}

export default HeroSlider
