import location from "assets/imgs/theme/icons/location.png"
import { Icon } from "leaflet"
import React from "react"
import { Marker, Popup, TileLayer, Map } from "react-leaflet"
import PropTypes from "prop-types"

export default function UseMap({ position }) {
  const myIcon = new Icon({
    iconUrl: location,
    iconSize: [35, 40],
  })
  return (
    <>
      <Map
        className="markercluster-map"
        center={position}
        zoom={20}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={myIcon}>
          <Popup>
            babyday, osperb edisonvalley,
            <br />
            NH-966, Up hill Malappuram,
            <br />
            Kerala 676505
          </Popup>
        </Marker>
      </Map>
    </>
  )
}

UseMap.propTypes = {
  position: PropTypes.any,
}
