import React, { useState } from "react"
import SwiperCore, { Navigation, Thumbs } from "swiper"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import { Swiper, SwiperSlide } from "swiper/react"
import PropTypes from "prop-types"
import { defualtProd } from "assets/imgs/logos"
import useCloudinary from "hooks/useCloudinaryImage"

SwiperCore.use([Navigation, Thumbs])

const ThumbSlider = ({ product }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  return (
    <div>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        className="mySwiper2 mt-2 "
      >
        {product?.images?.map((item, key) => (
          <SwiperSlide key={key}>
            <div className="main_image_slider">
              <img
                src={useCloudinary(item.url, "w_800")}
                className="fit-contain"
                height="100%"
                width={"100%"}
              />
            </div>
          </SwiperSlide>
        ))}
        {product?.images?.length <= 0 && (
          <SwiperSlide>
            <div className="main_image_slider">
              <img src={defualtProd} height="100%" width={"100%"} />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        className="mySwiper mt-2 custom_swipe"
      >
        {product?.images?.map((item, key) => (
          <SwiperSlide key={key} className="d-flex justify-content-center">
            <img
              src={useCloudinary(item.url, "c_thumb,w_400")}
              className="cursor-pointer"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default ThumbSlider

ThumbSlider.propTypes = {
  product: PropTypes.any,
}
