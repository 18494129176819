import React, { useEffect } from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useDispatch, useSelector } from "react-redux"
import { getBanners } from "store/actions"
import { map } from "lodash"

import BannerLoader from "components/Common/loaders/BannerLoader"

import "swiper/swiper.min.css"
import "swiper/swiper-bundle.min.css"
import { Link } from "react-router-dom"
import useCloudinary from "hooks/useCloudinaryImage"

SwiperCore.use([Navigation, Pagination])

const Intro1 = () => {
  const dispatch = useDispatch()

  const { banner, loading } = useSelector(state => ({
    banner: state.Banner.banners,
    loading: state.Banner.loading,
  }))

  useEffect(() => {
    dispatch(getBanners())
  }, [dispatch])

  const heroBanner = banner?.mainBanners

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        pagination={false}
        navigation={{
          prevEl: ".custom_prev_i1",
          nextEl: ".custom_next_i1",
        }}
        className={`hero-slider-1 style-4 dot-style-1 dot-style-1-position-1 ${
          heroBanner?.length >= 2 ? "" : ""
        }`}
        // onlyOneBanner
      >
        {loading ? (
          <SwiperSlide>
            <BannerLoader innerClass="redBanner" />
          </SwiperSlide>
        ) : (
          <>
            {map(heroBanner, (ban, key) => (
              <SwiperSlide key={key}>
                <Link to={`/collections/${ban?.link?.replace(/ /g, "-")}`}>
                  <div className="scorio_slider single-hero-slider single-animation-wrap">
                    <img
                      loading="lazy"
                      src={useCloudinary(ban?.images[0]?.url, `q_auto:eco`)}
                      className="w-100 h-100 border-radious-0"
                    />
                    <div className="slider-content"></div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>

      <div className="slider-arrow hero-slider-1-arrow scorio_arrow">
        <span className="slider-btn slider-prev slick-arrow custom_prev_i1">
          <i className="fi-rs-angle-left"></i>
        </span>
        <span className="slider-btn slider-next slick-arrow custom_next_i1">
          <i className="fi-rs-angle-right"></i>
        </span>
      </div>

      {/* <div className="fade-all"></div> */}
    </>
  )
}

export default Intro1
